import { useFormModalStore } from "@/store/modals/formModalStore.ts";
import { Dialog, DialogContent } from "@/components/ui/dialog.tsx";
import HeroForm from "@/components/sections/hero/HeroForm.tsx";
import { H3 } from "@/components/atoms/text/Headings.tsx";
import Stack from "@/components/atoms/Stack.tsx";
import Text from "@/components/atoms/text/Text.tsx";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useLocalStorage } from "@mantine/hooks";
import IconAlertCircle from "@/components/icons/IconAlertCircle.tsx";

const FormModal = () => {
  const [hasClosedForm, setHasClosedForm] = useLocalStorage({
    key: "hasClosedForm",
    defaultValue: "false",
  });

  const {
    isOpen,
    close,
    clickedProvider,
    hasPartnership,
    hasExclusivePartnership,
    isLeftPage,
  } = useFormModalStore((state) => ({
    isOpen: state.isOpen,
    close: state.close,
    hasPartnership: state.hasPartnership,
    hasExclusivePartnership: state.hasExclusivePartnership,
    clickedProvider: state.clickedProvider,
    isLeftPage: state.isLeftPage,
  }));

  return (
    <Dialog
      modal={true}
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          close();
          setHasClosedForm("true");
        }
      }}
    >
      <DialogContent
        className={
          "flex flex-col h-full md:h-auto overflow-y-auto bg-white backdrop-blur-md backdrop-filter bg-opacity-60"
        }
      >
        {isLeftPage ? (
          <Stack gap={"xs"}>
            <H3>Før du forlater siden! 😅</H3>
            <Text size={"sm"} variant={"subtle"}>
              Vi tror vi kan hjelpe deg med å finne det beste tilbudet for deg!
              Fyll ut skjemaet for å få et personlig tilbud
            </Text>
          </Stack>
        ) : (
          <Stack gap={"xs"}>
            <H3>
              {hasExclusivePartnership
                ? "Fyll ut skjema for å få et eksklusivt tilbud!"
                : "Fyll ut skjema for å få et personlig tilbud!"}
            </H3>
            <Text size={"sm"} variant={"subtle"}>
              {hasExclusivePartnership
                ? `Vi har et eksklusivt sammarbeid med ${clickedProvider}. En av våre rådgivere vil kontakte deg for å gi deg ditt tilbud så snart som mulig.`
                : "En av våre rådgivere vil kontakte deg for å gi deg et tilbud så snart som mulig."}
            </Text>
          </Stack>
        )}
        <HeroForm />
        {!hasPartnership && (
          <Alert className={"bg-white rounded-xl"}>
            <IconAlertCircle />
            <AlertTitle>
              Mobilabonnement har ikke samarbeid et med {clickedProvider}
            </AlertTitle>
            <AlertDescription>
              Vi vil allikevel hjelpe deg med å finne det beste tilbudet for
              deg!
            </AlertDescription>
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FormModal;
